<template>
  <f7-page name="install">
    <wuju-navbar title="Wuju" subtitle="be gentle to yourself" :menu="true" :back="false"></wuju-navbar>
    <f7-block>
      <f7-card class="theme-background">
        <f7-card-content>
          <div class="center large">
            <div>Install Wuju on your phone</div>
          </div>
        </f7-card-content>
      </f7-card>
      <f7-block>
        <!-- <p>Wuju is a website that can be installed as an app on your phone so it's always there when you need it the most.</p> -->
        <wuju-install></wuju-install>
      </f7-block>
      <f7-card class="theme-background">
        <f7-card-content>
          <div class="center large">
            <div>When should you use Wuju?</div>
          </div>
        </f7-card-content>
      </f7-card>
      <f7-block>
        <p>
          You can use Wuju any time but it's especially helpful when stress runs high:
        </p>
        <div class="box">
          <div>✅ &nbsp; When you can't focus</div>
          <div>✅ &nbsp; Before an stressful meeting</div>
          <div>✅ &nbsp; After a stressful call</div>
          <div>✅ &nbsp; Before bed</div>
        </div>
        <p>
          It takes just a few minutes.
        </p>
        <p>
          And it will make your life a lot better.
        </p>
      </f7-block>
    </f7-block>
    <f7-toolbar position="bottom">
      <f7-link></f7-link>
      <f7-button href="/home">Done</f7-button>
      <f7-link></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<style scoped>
  .box {
    margin: 25px 10px;
  }
</style>

<script>
import Narration from '../js/narration.js';
import Lessons from '../js/lessons.js';
export default {
  mounted() {
    this.$nextTick(() => {
      Narration.play(Lessons.introduction.narration.summary, 0, true);
    });
  },
}
</script>