var Topics = {
  work:
  {
    title: "My work",
    name: "Your work",
    description: "career, business, coworkers, future",
    feelings: ['tension', 'anger', 'fear', 'shame'],
  },
  money:
  {
    title: "My money or finances",
    name: "Your money or finances",
    description: "income, debt, the economy, desires",
    feelings: ['tension', 'craving', 'fear', 'scarcity'],
  },
  health:
  {
    title: "My health and fitness",
    name: "Your health and fitness",
    description: "physical health, mental health, fitness, weight",
    feelings: ['apathy', 'craving', 'fear', 'shame'],
  },
  relationships:
  {
    title: "My relationships",
    name: "Your relationships",
    description: "conflicts, things unsaid, promises broken",
    feelings: ['tension', 'anger', 'sadness', 'pride'],
  },
  family:
  {
    title: "My family",
    name: "Your family",
    description: "children, parents, siblings, distant family",
    feelings: ['tension', 'anger', 'sadness', 'pride'],
  },
  surroundings:
  {
    title: "My surroundings",
    name: "Your surroundings",
    description: "noise, discomfort, lack of space",
    feelings: ['tension', 'resistance', 'anger'],
  },
  global:
  {
    title: "Global issues",
    name: "Global issues",
    description: "the environment, the economy, war, desease",
    feelings: ['apathy', 'anger', 'fear', 'seriousness'],

  },
  everything:
  {
    title: "Everything",
    name: "Everything",
    description: "everything is stressful and overwhelming",
    feelings: ['apathy', 'tension', 'resistance', 'fear'],

  },
};

export default Topics;
