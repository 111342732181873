<template>
  <f7-page name="test">
    <f7-block>
      <!-- <div v-for="(v, k) in storage.insights.get()" :key="k">{{v.date}} {{v.feeling}} {{v.text}}`</div> -->
      <!-- <div v-for="(v, k) in storage.history.get()" :key="k">{{v.date}} {{v.reason}} {{v.location}} {{v.intensity}}`</div> -->
      <f7-button @click="add()">Add</f7-button>
    </f7-block>
  </f7-page>
</template>

<style scoped>
  .outer {
    width: 100%;
    height: 10px;
    /* border: 1px solid white; */
  }
  .inner {
    width: 0%;
    height: 100%;
    background: orange;
    margin: auto;
    border-radius: 20px;
  }
</style>

<script>
  import Storage from '../js/storage.js';

  export default {
    methods: {
      add() {
        // Storage.insights.add({text: 'was angry', feeling: 'anger'});
        // Storage.history.add({reason: 'bored', location: 'chest', intensity: {start: 50, end: 25}});
        Storage.lessons.set('momo');
      }
    },
    computed: {
      storage() {
        return Storage;
      }
    }
  }
</script>