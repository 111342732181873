var feelings = {
  // Surface feelings
  apathy: {
    name: 'Apathy',
    // description: 'depressed, numb, bored, exhausted, foggy',
    flavors: ['numbness', 'fogginess', 'exhaustion', 'burnout'],
    locations: ['mind', 'body', 'face', 'jaw', 'eyes', 'heart'],
    noun: /* Could you feel the */ 'apathy',
    category: 'Surface feelings',
    next: ['resistance', 'anger', 'fear', 'shame'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['life', 'money', 'family', 'work', 'health'],
    },
    help: {
      useful: `The apathy might be helping keep these strong emotions at bay and showing that you are a lot more sensitive than you know.`
    },
  },
  tension: {
    name: 'Tension',
    // description: 'stress, overwhelm, rush, tightness',
    locations: ['mind', 'head', 'face', 'jaw', 'eyes', 'neck', 'shoulders', 'chest', 'upper belly', 'lower belly'],
    flavors: ['stress', 'overwhelm', 'rush', 'tightness'],
    noun: /* Could you feel the */ 'tension',
    adjective: 'tense',
    category: 'Surface feelings',
    next: ['fear', 'doubt', 'peace', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['time', 'money', 'family', 'work', 'health'],
    },
    help: {
      useful: `The tension might be can be useful for a little when you need to handle an extreme situation, but it might be covering a deeper pain that you've been refusing to acknowledge.`
    },
  },
  craving: {
    name: 'Craving',
    // description: 'can\'t stop, can\'t let go, addicted, must have',
    flavors: ['need', 'lack', 'addiction'],
    locations: ['mouth', 'hands', 'chest', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'craving',
    category: 'Surface feelings',
    next: ['anger', 'shame', 'peace', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What are you craving for?`,
      options: ['love', 'safety', 'intimacy', 'touch', 'distraction', 'food', 'a smoke', 'a drink', 'quiet', 'space'],
    },
    help: {
      useful: `The craving might be telling you that you need to slow down, look deeper, and find out what you need lack and what you've been running away from.`
    },
  },
  resistance: {
    name: 'Resistance',
    // description: 'stuck, confused, unmoving, can\'t decide',
    flavors: ['confusion', 'creative block', '"don\'t wanna"'],
    locations: ['mind', 'face', 'eyes', 'jaw', 'hands', 'chest', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'resistance',
    category: 'Surface feelings',
    next: ['anger', 'fear', 'sadness'],
    stuck: ['doubt', 'fear'],
    extra: {
      question: `What are you resisting?`,
      options: ['my thoughts', 'my feelings', 'my body', 'my life', 'my past', 'my future', 'everything'],
    },
    help: {
      useful: `The resistance can be a sign that your boundaries are being crossed and that you need to stand up for what you believe in.`
    },
  },

  // Core emotions
  fear: {
    name: 'Fear',
    // description: 'worry, restlessness, anxiety, panic',
    flavors: ['worry', 'restlessness', 'anxiety', 'dread'],
    locations: ['face', 'mouth', 'neck', 'arms', 'chest', 'upper belly', 'lower belly', 'upper back', 'lower back', 'knees'],
    noun: /* Could you feel the */ 'fear',
    category: 'Core emotions',
    next: ['anger', 'sadness', 'courage'],
    stuck: ['resistance', 'doubt'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['letting go', 'the future', 'loneliness', 'embarrassment', 'failure', 'success', 'regret', 'pain', 'death'],
    },
    help: {
      useful: `The fear might be telling you that you're on the edge of your comfort zone and might need to push through.`,
    },
    faq: {
      could: {
        question: `If I let go of fear I'll do something stupid`,
        answer: `
          It might seem like if you let go of fear, you'll immediately be forced to do whatever you're afraid of. That's not true. You'll still have your decision making intact and, if anything, more choice.
        `,
      }
    }
  },
  anger: {
    name: 'Anger',
    // description: 'annoyance, frustration, bitterness, disappointment',
    flavors: ['annoyance', 'frustration', 'bitterness', 'disappointment', 'jealousy'],
    locations: ['face', 'jaw', 'shoulders', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'anger',
    category: 'Core emotions',
    next: ['sadness', 'shame', 'gratitude'],
    stuck: ['resistance', 'fear', 'pride'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'other people', 'my life', 'my body', 'my thoughts', 'my emotions', 'everything'],
    },
    help: {
      useful: `The anger might be telling you your boundaries have been crossed and you haven't taken good care of yourself. Or that your behavior is not aligned with your values.`
    },
  },
  sadness: {
    name: 'Sadness',
    // description: 'loneliness, self pity, heartbreak, pain, grief',
    flavors: ['loneliness', 'pain', 'loss', 'grief', 'self pity'],
    locations: ['face', 'eyes', 'mouth', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'sadness',
    category: 'Core emotions',
    next: ['gratitude', 'peace'],
    stuck: ['resistance', 'doubt', 'seriousness'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'my past', 'my future', 'my home', 'my life', 'love', 'death', 'everything'],
    },
    help: {
      useful: `The sadness might be telling you that nothing in life is permanent, that you need to appreciate what you have while you have it, and live in the present instead of the past or the future.`
    },
  },
  shame: {
    name: 'Shame',
    // description: 'embarrassment, humiliation, failure',
    flavors: ['embarrassment', 'guilt', 'inadequacy', 'failure', 'self-judgement', 'worthlessness'],
    locations: ['face', 'ears', 'mouth', 'throat', 'hands', 'heart', 'upper belly', 'lower belly'],
    noun: /* Could you feel the */ 'shame',
    category: 'Core emotions',
    next: ['fear', 'anger', 'sadness'],
    stuck: ['resistance', 'doubt', 'fear'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['my thoughts', 'my feelings', 'my body', 'my mind', 'my life', 'my past', 'my future', 'everything'],
    },
    help: {
      useful: `The shame might be pointing out that you're lacking in self love and self compassion, that you should be gentler and more forgiving with yourself and others. Or that you need to make amends and learn from your mistakes.`
    },
  },

  // Mindsets
  doubt: {
    name: 'Doubt',
    // description: 'skeptical, suspicious, self-doubt',
    flavors: ['self-doubt', 'distrust', 'skepticism', 'cynicism'],
    noun: /* Could you feel the */ 'doubt',
    category: 'Mindsets',
    stuck: ['resistance', 'fear', 'seriousness'],
    next: ['apathy', 'resistance', 'tension'],
    extra: {
      question: `What is your {feeling.noun} about?`,
      options: ['myself', 'my feelings', 'my thoughts', 'the future', 'this process', 'other people', 'everything'],
    },
    help: {
      useful: `The doubt might be showing you that you don't trust yourself, your intuition and your values to guide you. Or that you don't trust yourself to course correct as needed.
      `
    },
  },
  pride: {
    name: 'Pride',
    // description: 'arrogant, hurt ego, you\'re wrong, I\'m right',
    flavors: ['arrogance', 'ego', 'righteousness'],
    noun: /* Could you feel the */ 'pride',
    category: 'Mindsets',
    next: ['sadness', 'gratitude', 'fear', 'anger'],
    stuck: ['resistance', 'doubt', 'fear'],
    help: {
      useful: `The pride might be telling you that you've lost track, became detached from what is truly important to you and are about to push important people away.`
    },
  },
  scarcity: {
    name: 'Scarcity',
    // description: 'sense of lack, not enough, too late, need more',
    flavors: ['lack', '"not enough"', '"too late"'],
    noun: /* Could you feel the */ 'scarcity',
    category: 'Mindsets',
    next: ['gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
  },
  seriousness: {
    name: 'Seriousness',
    // description: 'when you let go of gravity, everything seems lighter',
    flavors: ['gravity', 'inevitability', 'hopelessness'],
    noun: /* Could you feel the */ 'seriousness',
    category: 'Mindsets',
    next: ['joy', 'gratitude'],
    stuck: ['resistance', 'doubt', 'fear'],
  },

  // Positive emotions
  gratitude: {
    name: 'Gratitude',
    description: 'abundance, trust, acceptance',
    noun: /* Could you feel the */ 'gratitude',
    category: 'Positive emotions',
    flow: 'gratitude',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  courage: {
    name: 'Courage',
    description: 'passionate, excited, determined',
    noun: /* Could you feel the */ 'courage',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  joy: {
    name: 'Joy',
    description: 'joyful, happy, cheerful',
    noun: /* Could you feel the */ 'joy',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
  peace: {
    name: 'Peacefulness',
    description: 'soft, loving, warm, pleasant',
    noun: /* Could you feel the */ 'peacefulness',
    category: 'Positive emotions',
    flow: 'positive',
    stuck: ['resistance', 'doubt', 'fear'],
  },
};

Object.keys(feelings).forEach(key => {
  feelings[key].help = feelings[key].help || {};
  feelings[key].stuck = feelings[key].stuck || ['resistance', 'doubt', 'fear'];
  feelings[key].extra = feelings[key].extra || {};
  feelings[key].full = feelings[key].noun;
  feelings[key].key = key;
});

export default feelings;
