<template>
  <f7-page name="home">
    <wuju-navbar :menu="true" :back="true" title="Insights"></wuju-navbar>
    <div v-if="sortedInsights.length">
      <f7-card v-for="(insight, index) in sortedInsights" :key="index">
        <f7-card-content>
          <div v-html="linebreaks(insight.text)"></div>
        </f7-card-content>
        <f7-card-footer>
          <p class="flex">{{insight.feeling ? (insight.feeling + ',') : ''}} {{moment(insight.date).fromNow()}}</p>
          <span>
            <f7-button class="inline" @click="share($event.target, insight)" icon-ios="f7:square_arrow_up" icon-md="material:share" icon-size="20"></f7-button>
          </span>
        </f7-card-footer>
      </f7-card>
    </div>
    <f7-block v-if="! sortedInsights.length">
      <p class="center">There are no insights here yet. Anything you write down as part of your process will appear here.</p>
    </f7-block>
    <f7-popover class="share-menu" :backdrop="false" ref="shareMenu">
      <wuju-share style="margin: 5px 0 0 -10px;" :message="current.text"></wuju-share>
    </f7-popover>
  </f7-page>
</template>

<script>
import moment from 'moment';
import Utils from '../js/utils.js';

export default {
  data: function() {
    return {
      current: {},
    }
  },
  methods: {
    share(el, insight) {
      this.current = insight;
      this.$refs.shareMenu.open(el);
    },
    moment: moment,
    ...Utils,
  },
  computed: {
    sortedInsights() {
      return this.$storage.insights.get().filter((v) => !!v.text).sort((a, b) => moment(b.date) - moment(a.date));
    }
  }
}

</script>
