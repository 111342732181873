<template>
  <f7-card class="theme-background">
    <f7-card-content class="content">
      <f7-link class="next" icon-f7="arrow_clockwise" :icon-size="12" @click="nextQuote()"></f7-link>
      <div class="quoted large" v-html="quote.text"></div>
      <div class="author" v-html="quote.author"></div>
    </f7-card-content>
  </f7-card>
</template>

<style scoped>
.quoted {
  margin-left: 25px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.quoted::after {
  content: '❞';
  font-size: 24px;
  position: absolute;
  top: -10px;
  left: -30px;

}

.author {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 70%;
  font-style: italic;
}

.next {
  position: absolute;
  bottom: 10px;
  left: 12px;
  font-size: 70%;
  font-style: italic;
  color: var(--f7-block-text-color);
}
</style>

<script>
import Utils from '../js/utils.js';
import Quotes from '../js/quotes.js';
export default {
  props: {
    quotes: Array,
  },
  data() {
    return {
      quote: Utils.choice(Quotes),
    }
  },
  methods: {
    nextQuote() {
      this.quote = Utils.choice(Quotes);
    },
  }
}
</script>