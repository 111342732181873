<template>
  <f7-list>
    <f7-list-item title="What are you stressed about?" group-title></f7-list-item>
    <f7-list-item @click="gotoTopic(key)" link="#" v-for="(topic, key) in topics" :key="key" :title="topic.title" :footer="topic.description"></f7-list-item>
  </f7-list>
</template>

<style scoped>

</style>

<script>
import Utils from '../js/utils.js';
import Topics from '../js/topics.js';

export default {
  props: {
    // custom exit url when going into a feeling
    exiturl: { type: String, default: null },
    play: { type: String, default: null },
  },
  data: function() {
    return {
    }
  },
  methods: {
    gotoTopic(key) {
      this.$f7router.navigate(`/topics/${key}`, { props: { exiturl: this.exiturl, play: this.play } });
    },
    ...Utils,
  },
  computed: {
    topics() {
      return Topics;
    }
  }
};
</script>
