<template>
  <div>
    <div v-if="device === 'ios'" class="box">
      <div>👉 &nbsp; Click the <f7-icon f7="square_arrow_up" size="20" style="position: relative; top: -2px;"></f7-icon> icon below</div>
      <div>👉 &nbsp; Click "Add to Home Screen"</div>
      <br>
      <div class="bullet">❓ &nbsp; If you don't see this option, copy the link and open it in Safari.</div>
      <div class="link-box"><f7-link @click="copyLink(url)" :href="url">{{url}}</f7-link></div>
    </div>
    <div v-if="device === 'android'" class="box">
      <div>👉 &nbsp; Open your browser menu &nbsp; (<f7-icon material="more_vert" size="20"></f7-icon>)</div>
      <div>👉 &nbsp; Click "Install app"</div>
      <br>
      <div class="bullet">❓ &nbsp; If you don't see this option, copy the link and open it in Chrome.</div>
      <div class="link-box"><f7-link @click="copyLink(url)" :href="url">{{url}}</f7-link></div>
    </div>
    <div v-if="device === 'other' && ! sent && showEmail" class="box">
      <p>Send yourself a link to Wuju so you can open and install it on your phone.</p>
      <f7-button @click="sendLink()">Send me the link</f7-button>
    </div>
  </div>
</template>

<style scoped>
  .box {
    margin: 0 10px;
  }
  .link-box {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    margin-top: 20px;
  }
  .bullet {
    padding-left: 26px ;
    text-indent: -26px ;
  }
</style>

<script>
import Utils from '../js/utils.js';
import Email from '../js/email.js';

export default {
  props: {
    showEmail: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    sendLink() {
      Email.send('wuju_template_install', { email: this.$storage.misc.email, link: window.location.href } );
      this.$f7.toast.show({
        text: 'Email sent!',
        closeTimeout: 3000,
      });
    },
    contact() {
      Email.openContactDialog(this.$f7, `Can't install?`, `Let me know what's going on and I'll help you out.`);
    },
    copyLink(link) {
      this.$clipboard(link);
      this.$f7.toast.show({
        text: 'Link copied to clipboard!',
        closeTimeout: 3000,
      });
    },
    ...Utils,
  },
  computed: {
    device() {
      if (this.$f7.device.standalone) return;
      if (this.$f7.device.ios) return 'ios';
      if (this.$f7.device.androidChrome) return 'android';

      return 'other';
    },
    url() {
      return window.location.href;
    },
  },
}
</script>