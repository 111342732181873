import Utils from './utils.js';
import Storage from './storage.js';
import EmailJS from 'emailjs-com';

var Email = {
  init() {
    EmailJS.init('user_xwKXG8TmQ1uy9VXG2w1yP');
  },

  openContactDialog($f7, title, text) {
    text = text; // || 'I answer every email I get.'
    $f7.dialog.create({
      title: title || `How can I help?`,
      content:
        (text ? `<p style="font-size: 90%; padding-bottom: 10px; border-bottom: 1px #eee solid; color: black;">${text}</p>` : `<br>`) + `
          <textarea placeholder="type your message here..." style="margin-top: 10px; min-height: 100px; max-height:200px; width: 100%;" class="dialog-message resizable"></textarea>
          <img style="width: 30px; border-radius: 5px; position: absolute; top: 10px; right: 10px;" src="static/img/eli.jpg">
          <input style="${Storage.misc.email ? 'display: none' : ''}" type="text" class="dialog-email" placeholder="your email" value="${Storage.misc.email}"></input>
        `,
      buttons: [
        { text: 'Cancel', color: 'theme', keyCodes: [27] },
        { text: 'Send', color: 'theme', bold: true, close: false },
      ],
      onClick(dialog, index) {
        const email = dialog.$el.find('.dialog-email').val();
        const message = dialog.$el.find('.dialog-message').val();
        if (index === 1 && email !== '' && message !== '') {
          EmailJS.send('wuju_gmail', 'wuju_template_message', {
            email: email,
            subject: Utils.shorten(message, 5),
            message: message,
          }).catch((e) => {
            console.log(e);
          });
          dialog.close();
        }
      },
      destroyOnClose: true,
    }).open();
  },

  send(template, args) {
    return EmailJS.send('wuju_gmail', template, args).catch((e) => {
      console.log(e);
    });
  }
};

export default Email;