
import Storage from './storage.js';``
import HomePage from '../pages/home.vue';
import TopicPage from '../pages/topic.vue';
import SensationPage from '../pages/sensation.vue';
import LessonPage from '../pages/lesson.vue';
import LessonsPage from '../pages/lessons.vue';
import SummaryPage from '../pages/summary.vue';
import SettingsPage from '../pages/settings.vue';
import InsightsPage from '../pages/insights.vue';
import HistoryPage from '../pages/history.vue';
import AboutPage from '../pages/about.vue';
import IntroPage from '../pages/intro.vue';
import ReleasePage from '../pages/release.vue';
import TermsPage from '../pages/terms.vue';
import PrivacyPage from '../pages/privacy.vue';
import GraphPage from '../pages/graph.vue';
import TestPage from '../pages/test.vue';
import ViewPage from '../pages/view.vue';
import InstallPage from '../pages/install.vue';

var routes = [
  {
    path: '/',
    asyncComponent: () => {
      if (Storage.shown.get('intro')) {
        return import(`../pages/home.vue`);
      } else {
        return import(`../pages/intro.vue`);
      }
    },
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/about',
    component: AboutPage,
  },
  {
    path: '/intro',
    component: IntroPage,
  },
  {
    path: '/feelings/other',
    component: ReleasePage,
    options: {
      props: {
        flow: 'other'
      }
    },
  },
  {
    path: '/feelings/:feeling',
    component: ReleasePage,
    options: {
      props: {
        flow: 'default'
      }
    },
  },
  {
    path: '/topics/:topic',
    component: TopicPage,
  },
  {
    path: '/sensations/:category/:index',
    component: SensationPage,
  },
  {
    path: '/lessons',
    component: LessonsPage,
  },
  {
    path: '/lessons/:lesson',
    component: LessonPage,
  },
  {
    path: '/lessons/:lesson/summary',
    component: SummaryPage,
  },
  {
    path: '/settings',
    component: SettingsPage,
  },
  {
    path: '/insights',
    component: InsightsPage,
  },
  {
    path: '/history',
    component: HistoryPage,
  },
  {
    path: '/terms',
    component: TermsPage,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
  },
  {
    path: '/survey',
    component: ReleasePage,
    options: {
      props: {
        flow: 'survey',
        toolbar: false,
        title: 'Wuju Survey',
      }
    },
  },
  {
    path: '/graph/:flow',
    component: GraphPage,
  },
  {
    path: '/test',
    component: TestPage,
  },
  {
    path: '/install',
    component: InstallPage,
  },
  {
    path: '/view/:group',
    component: ViewPage,
  },
  {
    path: '/share/:group',
    component: HomePage,
    options: {
      props: {
        shared: true,
      }
    },
  }
];

export default routes;
