<template>
  <f7-page name="lesson">
    <wuju-navbar title="Wuju" subtitle="be gentle to yourself" :menu="true" :back="false"></wuju-navbar>
    <f7-block>
      <f7-card class="theme-background">
        <f7-card-content>
          <div class="large center" v-html="lesson.title"></div>
        </f7-card-content>
      </f7-card>
      <br>
      <wuju-contact></wuju-contact>
      <p class="content" v-html="linebreaks(lesson.text.main)"></p>
    </f7-block>
    <wuju-topic-list :play="lesson.narration.secondary"></wuju-topic-list>
  </f7-page>
</template>

<style scoped>
  .content {
    min-height: 100px;
  }
</style>

<script>
import Lessons from '../js/lessons.js';
import Utils from '../js/utils.js';
import Narration from '../js/narration.js';

export default {
  props: {
    delay: {
      type: Number,
      default: 1000,
    }
  },
  data: function() {
    return {
        lesson: {},
    }
  },
  methods: {
    ...Utils,
  },
  beforeMount: function() {
    this.lesson = Lessons[this.$f7route.params.lesson];
  },
  mounted() {
    Narration.interrupt()
    .delay(this.delay)
    .play(this.lesson.narration.main);
  }
}
</script>