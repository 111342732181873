<template>
  <f7-page name="intro">
    <wuju-navbar :menu="false" :back="false" :music="end" title="Wuju" subtitle="be gentle with yourself"></wuju-navbar>
    <f7-swiper ref="swiper" pagination>
      <f7-swiper-slide>
          <div class="splash">
            <br>
            <div class="title">
              <h1 class="center">Relieve stress &<br class="normal"><span style="margin-left:-7px;">prevent burnout</span></h1>
              <h2 class="center">without a daily habit</h2>
            </div>
            <div class="banner">Wuju</div>
          </div>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p class="bold">There are a million stress relief techniques.</p>
          <p>And they could all work if you could stick to the habit.</p>
          <p>But a stressful life makes sticking to habits very difficult.</p>
          <p class="bold">Let's fix this.</p>
        </f7-block>
        <p class="emblem"><f7-icon f7="hand_point_right" size="80" color="theme"></f7-icon></p>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p class="bold">Only use Wuju when you need it.</p>
          <ol class="text">
            <li>Notice you're stressed.</li>
            <li style="margin-top: -10px;">Tap <img src="/static/icons/128x128.png" style="border: 1px solid #e5e5e5; border-radius: 5px; padding: 5px; width:26px; position:relative; top: 12px; margin: 0 3px;"> on your phone.</li>
            <li>Go through the 3-minute process.</li>
          </ol>
          <div class="bold">Resume life with clarity and focus.</div>
        </f7-block>
        <p class="emblem"><f7-icon f7="hand_thumbsup" size="80" color="theme"></f7-icon></p>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <f7-block inset>
          <p class="bold">Find clarity and focus in 3 minutes.</p>
          <p>Wuju works best when you have headphones on some privacy.</p>
          <f7-list no-hairlines no-hairlines-between>
            <f7-list-item :title="music ? 'Music & narration enabled' : 'Music & narration disabled'"><f7-toggle slot="after" :checked="music" @change="music = !music"></f7-toggle></f7-list-item>
            <f7-list-input type="email" required placeholder="your email" footer="" @change="email = $event.target.value" :value="email"></f7-list-input>
            <f7-list-item footer="I'm asking for your email so I can later offer you a premium subscription."></f7-list-item>
          </f7-list>
          <p class="small error-message hidden center">Please enter your email to continue.</p>
          <!-- <wuju-install :show-email="false" style="border: 1px solid #e5e5e5; border-radius: 10px; padding: 10px 0px;"></wuju-install> -->
          <div class="bottom">
            <p class="small">Built in public by <f7-link target="_blank" :external="true" href="https://twitter.com/finereli">@finereli</f7-link></p>
          </div>
        </f7-block>
        <br>
        <!-- <p class="emblem"><f7-icon f7="headphones" size="80" color="theme"></f7-icon></p> -->
      </f7-swiper-slide>
    </f7-swiper>
    <f7-toolbar position="bottom">
          <f7-link></f7-link>
          <f7-button round filled @click="next()">{{end ? `Let's Go` : `Next` }}</f7-button>
          <f7-link></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<style scoped>
  .splash {
    background-image: url(../static/img/linus-nylund-UCIZh0-OYPw-unsplash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -140px;
    width: 100%;
    height: 100%;
  }

  .title {
    color: #666;
    /* border: 1px solid black; */
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;;
    padding: 5px 5px;
    margin: 15px;
    line-height: 1.3;
  }

  .banner {
    position: relative;
    top: -10px;
    font-size: 80px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
    text-align: center;
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .block {
    font-size: 120%;
    height: calc(100% - 60px);
  }

  .scroll {
    overflow-y: scroll;
    margin-top: 0;
    height: 100%;
  }

  .emblem {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.7;
  }

  .bottom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.7;
  }

  .thumbnail {
    width: 80px;
    border-radius: 5px;
    float: right;
    margin: 20px 0 20px 20px;
  }

  .fab-right-bottom {
    margin-bottom: 60px;
  }

</style>

<script>
import Music from '../js/music.js';
import Guidance from '../js/guidance.js';
import Utils from '../js/utils.js';
import Email from '../js/email.js';
import Functions from '../js/functions.js';

export default {
  data: function() {
    return {
      slide: 0,
      end: false,
      music: true,
      email: '',
    };
  },
  methods: {
    next() {
      if (this.end) {
        if (! this.email) {
          // show error
          this.$$('.error-message').show();
          return;
        }
        this.exit();
      } else {
        this.swiper().slideNext();
      }
    },
    remind() {
      this.$f7.dialog.prompt(
        `Leave your email for a quick reminder to try the app later.`,
        `Reminder email`,
        (email) => {
          if (! email) return;
          Email.send('wuju_template_reminder', { email: email, link: window.location.origin } );
          this.next();
        },
        () => {},
      );
    },
    exit() {
      this.$storage.shown.set('intro');
      this.$storage.misc.email = this.email;
      Functions.subscribe('', this.email);
      if (this.music) {
        Music.play();
      }
      this.$f7router.navigate('/lessons/introduction', { clearPreviousHistory: true, props: { delay: 2000 } });
    },
    swiper() {
      if (! this.$refs.swiper) return;
      return this.$refs.swiper.swiper;
    },
    ...Utils,
  },
  computed: {
    guidance() {
      return Guidance;
    },
    device() {
      if (this.$f7.device.standalone) return;
      if (this.$f7.device.ios) return 'ios';
      if (this.$f7.device.androidChrome) return 'android';

      return 'other';
    },
  },
  mounted() {
    // manually listen to events because the F7 Swipe Vue component lacks them
    this.swiper().on('reachEnd', () => {
      this.end = true;
    });
  }
}
</script>